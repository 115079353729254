import React from 'react'

// Styles
import { BreadcrumbOuter } from './style'

import { pageSectionMap } from '../../../constants/urlSections.constants'

type BreadcrumbProps = {
  currentPathname: string
  theme: 'light' | 'dark'
  pageName: string
  className?: string
  isBlogBreadcrumb?: boolean
  pageType?: string
}
const ArrowRight = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="https://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
    >
      <g
        id="Library"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="16x16/ArrowSmall_Right" fill="#000000">
          <path
            d="M10.5237615,3.54623846 C10.7854128,3.80788973 10.7854128,4.23211027 10.5237615,4.49376154 L7.647,7.37 L10.5237615,10.2462385 C10.7854128,10.5078897 10.7854128,10.9321103 10.5237615,11.1937615 C10.2621103,11.4554128 9.83788973,11.4554128 9.57623846,11.1937615 L6.22623846,7.84376154 C5.96458718,7.58211027 5.96458718,7.15788973 6.22623846,6.89623846 L9.57623846,3.54623846 C9.83788973,3.28458718 10.2621103,3.28458718 10.5237615,3.54623846 Z"
            id="icon"
            transform="translate(8.375000, 7.370000) scale(-1, 1) translate(-8.375000, -7.370000) "
          ></path>
        </g>
      </g>
    </svg>
  )
}
const HomeIcon = () => {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      version="1.1"
      xmlns="https://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
    >
      <defs>
        <path
          d="M6.7,14.7398325 C6.7,15.1096725 6.39984,15.4098325 6.03,15.4098325 L2.34299,15.4098325 C1.41772,15.4098325 0.67,14.6607725 0.67,13.7328225 L0.67,8.0378225 C0.67,7.2874225 1.09947,6.3601425 1.67165,5.8737225 L7.60584,0.8292925 C7.85642,0.6169025 8.22358,0.6169025 8.47416,0.8292925 L14.40835,5.8737225 C14.98187,6.3608125 15.41,7.2867525 15.41,8.0378225 L15.41,13.7328225 C15.41,14.6614425 14.66094,15.4098325 13.73433,15.4098325 L10.05,15.4098325 C9.68016,15.4098325 9.38,15.1096725 9.38,14.7398325 L9.38,10.7198325 L6.7,10.7198325 L6.7,14.7398325 Z M13.73433,14.0698325 C13.92126,14.0698325 14.07,13.9210925 14.07,13.7328225 L14.07,8.0378225 C14.07,7.6800425 13.81473,7.1272925 13.5407,6.8948025 L8.04,2.2188725 L2.5393,6.8948025 C2.26661,7.1266225 2.01,7.6807125 2.01,8.0378225 L2.01,13.7328225 C2.01,13.9210925 2.15807,14.0698325 2.34299,14.0698325 L5.36,14.0698325 L5.36,10.0498325 C5.36,9.6799925 5.66016,9.3798325 6.03,9.3798325 L10.05,9.3798325 C10.41984,9.3798325 10.72,9.6799925 10.72,10.0498325 L10.72,14.0698325 L13.73433,14.0698325 Z"
          id="path-1"
        ></path>
      </defs>
      <g
        id="Library"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="16x16/Home">
          <mask id="mask-2" fill="white">
            <use xlinkHref="#path-1"></use>
          </mask>
          <use id="icon" fill="#000000" xlinkHref="#path-1"></use>
        </g>
      </g>
    </svg>
  )
}

const Breadcrumbs = (props: BreadcrumbProps) => {
  const generateBreadCrumbs = () => {
    let sections = []
    let pageSections = pageSectionMap['global']
    if (props.isBlogBreadcrumb) {
      pageSections = pageSectionMap['blog']
    }
    const urlSections = Object.keys(pageSections)
    for (let urlSection of urlSections) {
      let matchingIdx = props.currentPathname.indexOf(urlSection)
      if (matchingIdx !== -1) {
        let pageSection = pageSections[urlSection]
        if (pageSection.name !== props.pageName) {
          sections.push({
            name: pageSection.name,
            sectionUrl: pageSection.sectionUrl,
          })
        }
      }
    }

    return (
      <>
        <a href="/" className="Breadcrumbs__Icon Breadcrumbs__Home-Icon">
          <HomeIcon />
        </a>
        <div className="Breadcrumbs__Next-Icon Breadcrumbs__Icon">
          <ArrowRight />
        </div>
        {sections.map((section) => {
          return (
            <React.Fragment key={section.name}>
              <a href={section.sectionUrl}>
                <p className="Breadcrumbs__Link">{section.name}</p>
              </a>
              <div className="Breadcrumbs__Next-Icon Breadcrumbs__Icon">
                <ArrowRight />
              </div>
            </React.Fragment>
          )
        })}
        <p className="Breadcrumbs__Link Breadcrumbs__Link--Current">
          {props.pageName}
        </p>
      </>
    )
  }

  return (
    <BreadcrumbOuter
      className={props.className}
      breadcrumbTheme={props.theme}
      data-cy="Breadcrumbs"
      pageType={props.pageType}
    >
      {generateBreadCrumbs()}
    </BreadcrumbOuter>
  )
}

export default Breadcrumbs
